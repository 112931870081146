import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import Layout from '../layouts/layout';
import Grid from '../components/Grid';
// import StripeListing from '../components/StripeListing';

const StripeListing = () => null;

export default function Turkey(props) {
	return (
		<Layout title="Thanksgiving Turkey Dinner">
			<h1>Thanksgiving Turkey Dinner</h1>
			<p>
				<strong>
					The turkeys are sold out! If you have questions, please call
					us at <a href="tel:+17602957993">(760) 295-7993</a>.
				</strong>
			</p>
			<p>
				If you're looking for a dinner worthy of a Thanksgiving feast,
				you've come to the right place. We'll do the hard part, and you
				can take the credit for the good food.
			</p>
			<p>
				To read more about the process, see our{' '}
				<Link to="/turkey-faq">Turkey FAQ</Link>
			</p>

			<Grid>
				<div>
					<Img
						fluid={props.data.turkey1.childImageSharp.fluid}
						alt="smoked turkey on white plate with rosemary and cranberries"
					/>
					<br />
					<Img
						className="hide-xs"
						fluid={props.data.turkey2.childImageSharp.fluid}
						alt="man cutting fresh thanksgiving turkey"
					/>
				</div>
				<div>
					<StripeListing
						sku="turkey_with_sides"
						title="Smoked Turkey with Sides"
						price={225}
						description={
							<>
								<p>This is a complete Thanksgiving feast.</p>
								<p>
									This includes:
									<ul>
										<li>13-15 lb Smoked Turkey</li>
										<li>gravy</li>
										<li>garlic mashed potatoes</li>
										<li>green bean casserole</li>
										<li>stuffing</li>
										<li>corn muffins</li>
									</ul>
								</p>
							</>
						}
					/>
					<br />
					<br />
					<hr />
					<StripeListing
						sku="turkey"
						title="Smoked Turkey"
						price={98}
						description={
							<p>
								This includes a 13-15 lb smoked turkey. Each
								turkey feeds about 13-15 people
							</p>
						}
					/>
				</div>
			</Grid>
		</Layout>
	);
}

export const query = graphql`
	query {
		turkey1: file(relativePath: { eq: "turkey1.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 480) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
		turkey2: file(relativePath: { eq: "turkey2.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 480) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`;
